<template>
  <v-card>
    <v-toolbar :color="editing? 'color2 color2Text--text' : 'grey lighten-2 grey--text'" dense flat>
      Settings
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid class="pt-0">
      <v-row dense>
        <v-col cols="12" class="caption grey--text pt-0">
          Rating Sysytem Id: {{ratingSystem.id}}
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Rating System Name*"
            v-model="model.name"
            :disabled="!editing || loading"
            @change="$v.model.name.$touch()"
            :error-messages="nameErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Short Name*"
            v-model="model.shortName"
            :disabled="!editing || loading"
            @change="$v.model.name.$touch()"
            :error-messages="shortNameErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="First Drop Interval*"
            type="number"
            v-model="model.firstDropValue"
            :disabled="!editing || loading"
            @change="$v.model.firstDropValue.$touch()"
            :error-messages="firstDropValueErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="First Drop Date Part*"
            v-model="model.firstDropDatePart"
            :disabled="!editing || loading"
            @change="$v.model.firstDropDatePart.$touch()"
            :error-messages="firstDropDatePartErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Drop Interval*"
            type="number"
            v-model="model.dropInterval"
            :disabled="!editing || loading"
            @change="$v.model.dropInterval.$touch()"
            :error-messages="dropIntervalErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Drop Interval Date Part*"
            v-model="model.dropIntervalDatePart"
            :disabled="!editing || loading"
            @change="$v.model.dropIntervalDatePart.$touch()"
            :error-messages="dropIntervalDatePartErrors"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-expand-transition>
          <v-col cols="12" sm="6" v-if="needSeason">
            <v-select
              label="Season Start Month"
              :items="[
                { text: 'Jan', value: '01'},
                { text: 'Feb', value: '02'},
                { text: 'Mar', value: '03'},
                { text: 'Apr', value: '04'},
                { text: 'May', value: '05'},
                { text: 'Jun', value: '06'},
                { text: 'Jul', value: '07'},
                { text: 'Aug', value: '08'},
                { text: 'Sep', value: '09'},
                { text: 'Oct', value: '10'},
                { text: 'Nov', value: '11'},
                { text: 'Dec', value: '12'}
              ]"
              v-model="seasonMonth"
              :disabled="!editing || loading"
              color="color3"
              item-color="color3"
            ></v-select>
            <v-select
              label="Season Start Day"
              :items="days"
              v-model="seasonDay"
              :disabled="!editing || loading || days.length === 0"
              color="color3"
              item-color="color3"
            ></v-select>
          </v-col>
        </v-expand-transition>
        <v-col cols="12" sm="6">
          <v-select
            label="Limit to only these genders"
            :items="options.genderOptions"
            item-text="name"
            item-value="id"
            color="color3"
            item-color="color3"
            v-model="model.genderIds"
            multiple chips small-chips deletable-chips clearable
            attach
            :disabled="!editing || loading"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            label="Limit to only these surfaces"
            :items="[
              {name: 'Beach/Sand', id: 1},
              {name: 'Grass', id: 2},
              {name: 'Indoor', id: 3}
            ]"
            item-text="name"
            item-value="id"
            color="color3"
            item-color="color3"
            v-model="model.surfaceIds"
            multiple chips small-chips deletable-chips clearable
            attach
            :disabled="!editing || loading"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            label="Limit to only these formats"
            :items="[
              {name: 'KOB/QOB', id: 1},
              {name: 'Doubles', id: 2},
              {name: 'Triples', id: 3},
              {name: 'Quads', id: 4},
              {name: '5 on 5', id: 5},
              {name: '6s', id: 6}
            ]"
            item-text="name"
            item-value="id"
            color="color3"
            item-color="color3"
            v-model="model.formatIds"
            multiple chips small-chips deletable-chips clearable
            attach
            :disabled="!editing || loading"
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>

      </v-row>
      <!-- EDIT BUTTONS -->
      <v-row dense justify="end">
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            key="save"
            v-if="editing && dirty && !$v.$invalid"
            fab
            small
            class="my-0 mr-1"
            @click.stop="save"
            :loading="loading"
          >
            <v-icon small>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            fab
            small
            class="my-0"
            @click.stop="editing ? cancel() : editMe()"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'

export default {
  props: ['ratingSystem', 'options'],
  mixins: [validationMixin],
  validations () {
    return {
      model: {
        name: { required },
        shortName: { required },
        firstDropValue: { required, integer },
        firstDropDatePart: { required },
        dropInterval: { required, integer },
        dropIntervalDatePart: { required }
      }
    }
  },
  data () {
    return {
      editing: false,
      loading: false,
      editable: null
    }
  },
  computed: {
    seasonDateString () {
      return this.model.seasonStartDate.substring(0, 10)
    },
    seasonMonth: {
      get () {
        return moment(this.seasonDateString).format('MM')
      },
      set (val) {
        const m = moment(this.seasonDateString)
        this.model.seasonStartDate = `${m.format('YYYY')}-${val}-${m.format('DD')}`
      }
    },
    seasonDay: {
      get () {
        return moment(this.seasonDateString).format('DD')
      },
      set (val) {
        const m = moment(this.seasonDateString)
        this.model.seasonStartDate = `${m.format('YYYY')}-${m.format('MM')}-${val}`
      }
    },
    needSeason () {
      return [this.model.dropIntervalDatePart, this.model.firstDropDatePart].includes('S')
    },
    days () {
      const m = this.seasonMonth
      const days = m === '02' ? 28 : [].includes(m) ? 31 : 30
      return [...Array(days).keys()].map(x => ++x).map(m => m < 10 ? `0${m}` : `${m}`)
    },
    model () {
      return this.editing ? this.editable : this.ratingSystem
    },
    nameErrors () {
      const errors = []
      if (!this.$v.model.name.$dirty) return errors
      !this.$v.model.name.required && errors.push('A name is required')
      return errors
    },
    shortNameErrors () {
      const errors = []
      if (!this.$v.model.shortName.$dirty) return errors
      !this.$v.model.shortName.required && errors.push('A short name is required')
      return errors
    },
    firstDropDatePartErrors () {
      const errors = []
      if (!this.$v.model.firstDropDatePart.$dirty) return errors
      !this.$v.model.firstDropDatePart.required && errors.push('An expiration date part is required')
      return errors
    },
    firstDropValueErrors () {
      const errors = []
      if (!this.$v.model.firstDropValue.$dirty) return errors
      !this.$v.model.firstDropValue.required && errors.push('A expiration date value is required')
      !this.$v.model.firstDropValue.integer && errors.push('Please use whole numbers only')
      return errors
    },
    dropIntervalDatePartErrors () {
      const errors = []
      if (!this.$v.model.dropIntervalDatePart.$dirty) return errors
      !this.$v.model.dropIntervalDatePart.required && errors.push('An expiration date part is required')
      return errors
    },
    dropIntervalErrors () {
      const errors = []
      if (!this.$v.model.dropInterval.$dirty) return errors
      !this.$v.model.dropInterval.required && errors.push('A drop interval is required')
      !this.$v.model.dropInterval.integer && errors.push('Please use whole numbers only')
      return errors
    },
    dirty () {
      return this.editable && JSON.stringify(this.ratingSystem) !== JSON.stringify(this.editable) && this.editable.name
    },
    activeFab () {
      return this.editing ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    }
  },
  methods: {
    editMe () {
      this.editable = JSON.parse(JSON.stringify(this.ratingSystem))
      this.editing = true
    },
    cancel () {
      this.loading = false
      this.editing = false
      this.editable = null
    },
    save () {
      if (!this.dirty || !this.editable.name) return
      this.loading = true
      this.$VBL.ratings.post(this.editable)
        .then((response) => {
          this.$emit('changed', response.data)
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.cancel()
        })
    }
  }
}
</script>
